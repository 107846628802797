@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.messages::-webkit-scrollbar {
  width: 0.64vw;
}

.messages::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background-color: #1a1b1e;
}

.messages::-webkit-scrollbar-track {
  background-color: #2b2d31;
  border-radius: 2rem;
}


.message:hover .delete-icon {
  opacity: 1;
  z-index: 5;
}

.message:hover .edit-icon {
  opacity: 1;
  z-index: 6;
}

.message:hover .reply-icon {
  opacity: 1;
}

.reply-avatar {
  top: 28px;
}


a:hover {
  text-decoration: underline;
}

h1 {
  transform: scaleY(0.8);
}

.active {
  left: 40px;
}

.active-form {
  left: 0;
}

.active-header {
   width: 100%;
}

.active-box {
  width: calc(100% - 72px);
}

.active-menu-wrapper {
  opacity: 0;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}

.active-save {
  color: #959aa3;
}


.show {
  bottom: 0;
  pointer-events: all;
  
}

.close-icon {
  cursor: pointer;
  margin-top: 10px;
  color: #bfc2c5;
}

.close-icon:hover {
  color: #ffffff;
}

@media (min-width: 400px) {
  .active-menu-wrapper {
    opacity: 1;
  }
  .active-box {
    width: 100%;
  }
}
